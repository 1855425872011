.consumerCharts {
    display: flex;
    justify-content: space-between;
}
.usersTried {
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
    text-align: left;
    padding: 30px 50px;
}
.barChartUsersTried{
    border-radius: 4px;
    padding: 1% 2%;
    width: 49%;
    border: 2px solid #e8edf6;
}
