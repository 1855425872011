.pie {
  border: 2px solid #e8edf6;
  padding: 2% 2%;
  margin: 2% 0;
}
.pieChartHeading {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  text-align: left;
  padding: 30px 50px;
}
.pieData {
  width: 75%;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto auto auto;
  padding: 5% 0;
  font-size: 120%;
  text-align: center;
}
.pieDetails {
  padding: 0 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(229, 229, 229);
}

.name {
  display: flex;
  justify-content: space-between;
  margin-right: 5%;
}
.name p {
  margin-right: -10%;
}
.colorBoxValues {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* align-content: flex-end; */
}

.colorBox0,
.colorBox1,
.colorBox2,
.colorBox3,
.colorBox4,
.colorBox5,
.colorBox6,
.colorBox7,
.colorBox8,
.colorBox9 {
  border: 1px solid black;
  width: 20px;
  height: 10px;
  border-radius: 0.5em;
}
.colorBox0 {
  background-color: rgb(0, 48, 135);
}
.colorBox1 {
  background-color: rgb(208, 235, 249);
}
.colorBox2 {
  background-color: rgb(19, 115, 93);
}
.colorBox3 {
  background-color: rgb(31, 120, 180);
}
.colorBox4 {
  background-color: rgb(136, 187, 145);
}
.colorBox5 {
  background-color: rgb(255, 207, 97);
}
.colorBox6 {
  background-color: rgb(139, 47, 138);
}
.colorBox7 {
  background-color: rgb(247, 110, 32);
}
.colorBox8 {
  background-color: rgb(255, 115, 97);
}
.colorBox9 {
  background-color: rgb(190, 37, 37);
}

.pieChartDetails {
  display: flex;
  justify-content: space-evenly;
}

.ConsumerPieChart {
  width: 17%;
  height: 10%;
  margin-top: auto;
  margin-bottom: auto;
}
