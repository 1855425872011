.genderSection{
    /* padding: 40px; */
    border: 2px solid #e8edf6;
    border-radius: 4px;
    margin-left: 8px;
}
.genderTable{
    width: calc(100%);
    border-collapse: collapse;
    text-align: center;
    border: 2pz solid #e8edf6;
}
.productGenderHeadings,.productGenderSections{
    border: 2px solid #e8edf6;
}
.productGenderSections td{
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;
    color: #003087;
    padding: 20px;
    border: 2px solid #e8edf6;
}
.genderHeading{
    display: flex;
    justify-content: space-between;
    padding: 40px;
    align-items: center;
    font-weight: 600;
    font-size: 28px;
    /* align-items: center; */
    text-transform: capitalize;
    color: #0f1628;
}
.productGenderHeadings th{
    text-transform: capitalize;
    color: #0f1628;
    font-weight: 500;
    font-size: 22px;
    padding: 24px;
    border: 2px solid #e8edf6;
}
.ageWiseDetails td,.ageWiseDetails1 td{
    font-weight: 500;
    font-size: 26px;
    text-transform: capitalize;
    color: #003087;
    padding: 20px;
    border: 2px solid #e8edf6;
}
.ageWiseDetails td:first-child{
    color: #5b5c60;
    font-weight: 500;
    font-size: 22px;
    border-left: none;
}