.consumer {
  display: flex;
  justify-content: space-between;
  padding: 3% 0;
}
.box {
  width: 23%;
  padding: 10px 20px;
  display: flex;
  /* justify-content: space-between; */
}

.dataCategory {
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #5b5c60;
}
.value {
  display: flex;
  /* align-items: flex-end; */
  /* padding-top: 20px; */
  align-items: center;
}
.det {
  display: flex;
  font-weight: 600;
  font-size: 28px;
  text-transform: capitalize;
  color: #0f1628;
  align-items: baseline;
  padding-top: 10px;
}
.det p {
  font-size: 16px;
  font-weight: 400;
  margin-left: 5px;
}
.figure {
  color: #003087;
  font-size: 32px;
}
