.rating{
    display: flex;
  }
  .multiRatingChart{
    width: 50%;
  }
  
  .doughnut {
    width: 50%;
    margin: auto;
  }
  
  .legendLabels{
    display: flex;
    width: 250%;
    margin-left: -50px;
    /* justify-content: space-evenly; */
  }
  .ratingLabels{
    display: flex;
    align-items: center;
  }
  .ratingLabels *{
    margin: 4px;
  }
  
  .legend{
    font-size: 12px;
    padding:10px;
  }
  .legendColor1,.legendColor2,.legendColor3{
      width: 16px;
      height: 8px;
      border-radius: 20px;
  }
  .legendColor1{
    background-color: 
    rgb(238, 80, 79)
  }
  .legendColor2{
  background-color: 
    rgb(255, 207, 97)
  }
  .legendColor3{
  background-color: 
    rgb(108, 222, 129)
  }
  .ratingData{
    font-size: 18px;
    font-weight: bold;
  }