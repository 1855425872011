.totalProceed{
    background: #E9F0FF;
    border-radius: 9.5px;
    height: 20px;
    margin: 0px 10px;
    text-align: left;
    overflow: hidden;
    width: 250px;
}
.notProceededUsersTable{
    width: calc(100%);
    border-collapse: collapse;
    text-align: center;
    border: 2px solid #e8edf6;
}
.usersDontProceedSection{
    border: 2px solid #e8edf6;
    padding: 30px 50px;
}
.userProceeded{    
    height: 20px;
    background-color: #003087;
}
.notProceedDetails{
    padding: 20px;
    border-top: 1px solid #E5E5E5;
}
.usersNotProceededHeading{
    font-weight: 400;
    font-size: 16px;
    color: #003087;
}
.addCartHeader{
    font-weight: 500;
    font-size: 18px;
    text-align: left;
    text-transform: capitalize;
    padding: 20px 0;
}
.notProceedDetails td:last-child{
    display: flex;
    justify-content: center;
    align-items: center;
}
.triedPercentage{
    width: 60px;
}
.usersNotProceededHeading th{
    padding: 20px;
}
.notProceedDetails td{
    padding: 20px;
}
.checkedDetails{
    text-align: left;
    position: relative;
    right: -40px;
}