.collectionSection {
    display: flex;
    padding: 3% 0;
}


.collection {
    margin-right: 2%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    padding: 10px 15px;
    border: 1px solid #c4c4c4;
    background: #f5f6fa;
    text-align: start;
    width: 300px;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 25%) inset;
}