.card{
    background: #f5f6fa;
    box-shadow: 0px 4px 4px 1px rgb(0 0 0 / 25%);
    border-radius: 4px;
    margin: 9px;
    display: inline-block;
}
.productPercentage{
    margin: 20px 40px;
    font-weight: 500;
    font-size: 36px;
    color: #f2a000;
    padding-bottom: 0px;
}
.productLabel{
    padding: 10px 40px;
    padding-top: 0px;
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;
    color: #003087;
}
.trialSection,.adoptionSection{
    padding: 76px 0px;
    border-right: 2px solid #e8edf6;
    font-weight: 600;
    font-size: 22px;
    text-transform: uppercase;
    color: #0f1628;
    width: 160px;
    text-align: center;
}
.mainTrailContainer{
    background: #ffffff;
    border: 2px solid #e8edf6;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 16px 6px;
    /* position: relative; */
}
.productSection1,.productSection2{
    display: flex;
    align-items: center;
}
.productSection1{

    border-bottom: 2px solid #e8edf6;
}
                    