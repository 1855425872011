.geographyContainer{
    display: flex;
    justify-content: space-between;
    padding:  0 40px;
    align-items: center;
}
.productTable{
    width: calc(100%);
    border-collapse: collapse;
    text-align: center;
}
.geographyHeader{
    font-weight: 600;
    font-size: 28px;
    /* align-items: center; */
    text-transform: capitalize;
    color: #0f1628;
}
.productCompare{
    font-size: 18px;
    text-transform: capitalize;
    color: #272829;
    border: none;
    padding: 20px 40px;
    box-sizing: border-box;
    border-radius: 4px;
    box-sizing: border-box;
    background: #ffffff;
    /* padding-right: 20px; */
    box-shadow: 0px 4px 4px 1px rgb(0 0 0 / 25%);
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    margin: 20px 20px;
    cursor: pointer;
}
.geographySection{
    background: #ffffff;
    border: 2px solid #e8edf6;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 16px 6px;
    position: relative;
}
.productHeadings,.productDetails,.productHeadings th,.productDetails td,.productSections td{
    border: 2px solid #e8edf6;
}
.productTable td:first-child{
border-left: none;
}
.productHeadings th:last-child{
    border-right: none;
    /* position: relative;
    left: 25%;
    right: 25%; */
}


.productHeadings th{
    text-transform: capitalize;
    color: #0f1628;
    font-weight: 500;
    font-size: 22px;
    padding: 24px;
}
.productSections{
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;
    color: #003087;
    padding: 20px;
}
.productSections td{
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;
    color: #003087;
    padding: 20px;
}
.productDetails{
    font-weight: 500;
    font-size: 26px;
    text-transform: capitalize;
    color: #003087;
    padding: 20px;  
}
.productDetails td{
    font-weight: 500;
    font-size: 26px;
    text-transform: capitalize;
    color: #003087;
    padding: 20px;
}
.productDetails td:first-child{
    color: #5b5c60;
    font-weight: 500;
    font-size: 22px;
    border-left: none;
}