.App {
  text-align: center;
  overflow-x: hidden;
}

.App * {
  font-family: "Inter", sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  height: 54px;
  background: linear-gradient(90.21deg, #012a74 0%, #30348d 98.47%);
  display: flex;
  align-items: center;
  padding: 0% 3%;
  z-index: 1;
}

.user {
  display: flex;
  align-items: center;
  position: fixed;
  right: 8%;
  color: white;
  cursor: pointer;
}

.user * {
  margin-right: 15%;
}

.user img {
  background-color: white;
}

.hamberger,
.settings,
.chat,
.notification,
.userProfile {
  color: white;
  font-size: 170%;
  cursor: pointer;
  /* opacity: 1; */
}

.userProfile {
  font-size: 200%;
}

.headerOne {
  display: flex;
  /* justify-content: space-evenly; */
  /* position: fixed; */
  align-items: center;
}

.headerCenter {
  display: flex;
  margin-left: -100%;
  justify-content: space-evenly;
}

.headerCenter * {
  margin-left: 10%;
}

.selectBrand {
  padding: 2% 1%;
  font-size: 100%;
  text-align: left;
  border-radius: 4px;
  cursor: pointer;
  background-color: white;
}

.menu {
  position: fixed;
  top: 54px;
  left: 0px;
  bottom: 0px;
  /* padding: 0 20px; */
  background-color: rgb(232, 237, 246);
}

.home,
.dashboard,
.sampling,
.survey,
.chart,
.request {
  color: gray;
  margin: 0 14px;
}

.overview {
  background-color: white;
  padding-bottom: 20px;
}

.samplingList {
  font-weight: bold;
  padding: 10px;
}

.samplingList p {
  cursor: pointer;
}

.clicks {
  color: black;
}

.active {
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 48, 135);
  border-radius: 2em;
  padding-top: 15px;
  color: white;
}

.menus {
  display: flex;
  padding: 20px;
}

.home {
  display: flex;
  font-size: 12px;
}

.home p {
  color: rgb(0, 48, 135);
  margin-left: 5px;
}

.catData {
  margin-top: 54px;
  margin-left: 238px;
  border-bottom: 1px solid #c4c4c4;
}

.sample {
  display: flex;
  padding: 0.8%;
}

.category {
  display: flex;
  position: absolute;
  right: 10%;
}

.sample h2 {
  color: rgb(91, 92, 96);
  font-size: 30px;
}

.first,
.second {
  font-weight: 400;
}

.first,
.second {
  margin-right: 5%;

}

.catData .home {
  /* margin-top: 80px; */
  margin-bottom: -10px;
  text-align: left;
  color: black;
}

.first .catSection1 {
  width: 200px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 25%) inset;
  padding: 10px 15px;
  border-radius: 4px;
  border-bottom: 1px solid rgb(196, 196, 196);

}

.second .catSection2 {
  width: 200px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 25%) inset;
  padding: 10px 15px;
  border-radius: 4px;
  border-bottom: 1px solid rgb(196, 196, 196);
}

.mainCategory1,
.mainCategory2 {
  padding: 10px 15px;
  width: 200px;
  position: absolute;

  box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #fff;
  z-index: 1;
}

.cList,
.categoryList {
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
  padding: 5px 10px;
}

.categoryList,
.productList,
.pList,
.cList {
  display: flex;
  padding-left: 4px;
  align-items: center;
  margin-top: 5%;
}

.categoryList p,
.productList p,
.pList p,
.cList p {
  margin-top: 15px;
}

.categoryList input,
.productList input,
.pList input,
.cList input {
  margin-right: 10px;
  cursor: pointer;
}

.totalList {
  overflow-y: scroll;
  height: 250px;
}

.totalList::-webkit-scrollbar {
  display: none;
}

.productList p,
.categoryList p {
  text-align: left;
}

.search {
  width: 90%;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 2%;
  font-size: 1.2em;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 25%) inset;
}

.sample #date {
  width: 200px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 25%) inset;
  padding: 10px 5px;
  border-radius: 4px;
  border: 1px solid rgb(196, 196, 196);
  /* background-color: white; */
}

.daterangepicker {
  margin-right: 100px;
}

.mainCategoryPage {
  padding: 2%;
}

.catSection1,
.catSection2,
#date {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  padding: 10px 15px;
  border: 1px solid #c4c4c4;
  background: #f5f6fa;
  text-align: start;
}

/* metric boxes */

.boxes {
  margin-left: 238px;
}

.box {
  width: 23%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  box-shadow: 0px 3px 2px 1px rgba(0, 0, 0, 0.25);
  background-image: linear-gradient(278.95deg,
      #e8edf6 0%,
      rgba(232, 237, 246, 0) 100%);
  z-index: -2;
}

.dataCategory {
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #5b5c60;
}

.value {
  margin-top: -10px;
  margin-bottom: -10px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.det h2 {
  font-size: 28px;
  color: rgb(15, 22, 40);
}

/*  metric boxes */

.metricBoxes {
  display: flex;
  justify-content: space-between;
  padding: 3% 0;
}

.value {
  display: flex;
  align-items: flex-end;
  padding-top: 10px;
}

.det {
  display: flex;
  font-weight: 600;
  font-size: 28px;
  text-transform: capitalize;
  color: #0f1628;
  /* align-items: flex-end; */
}

.det p {
  font-size: 16px;
  font-weight: 400;
}

.miniGraph,
.miniGraph1,
.miniGraph2,
.miniGraph3 {
  width: 70px;
  height: 70px;
}

.show {
  text-align: left;
  padding: 0 1% 0 0;
  margin-top: 20px;
  margin-bottom: -20px;
  font-size: 14px;
}

/* .value p {
  font-size: 1em;
  color: green;
  font-weight: bold;
  margin-left: 5%;
} */

/* .graph{
  padding-top:2.2em;
  margin-bottom: -7%;
  margin-right: 4%;
} */
svg text {
  font-family: "Inter", sans-serif;
}

.maps {
  border: 2px solid #e8edf6;
  display: flex;
  /* margin-left: 8px; */
  padding: 2% 0%;
}

.map1,
.map2 {
  width: 50%;
  /* height: 700px; */
  padding: 1% 2%;
  padding-right: 5%;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.maps path,
#d3Second path {
  /* display: none; */
  color: #ecf0f1;
}

#d3demo {
  width: 110%;
  /* height: 95%; */
  /* height: 500px; */
  /* height: 70vh; */
  color: lightgray;
  /* margin-right: 5%; */
}

.brandVsCategoryBarGraph canvas {
  height: 500px;
}

.maleVsFemaleHeading,
.brandVsCategoryHeading {
  padding-left: 5%;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 500;
  margin-left: -5%;
  margin-bottom: 5%;
}

#d3Second {
  width: 110%;
  /* height: 70vh; */
  color: lightgray;
}

.tick text {
  color: gray;
}

.tick line {
  color: #ecf0f1;
}

.main {
  overflow-y: scroll;
  overflow-x: hidden;
}

.main::-webkit-scrollbar {
  display: none;
}

.chartFooter {
  display: flex;
  margin-top: -20px;
  justify-content: center;
}

.label {
  display: flex;
  margin: 5px;
  padding: 5px;
  color: black;
}

.labelBoxBrands,
.labelBoxCategories,
.labelBoxMale,
.labelBoxFemale {
  width: 15px;
  height: 15px;
  margin: 5px;
}

.labelBoxBrands {
  background-color: rgb(166, 206, 227);
}

.labelBoxCategories {
  background-color: rgb(0, 48, 135);
}

.labelBoxMale {
  background-color: rgb(56, 80, 180);
}

.labelBoxFemale {
  background-color: rgb(246, 69, 154);
}

/* product wise summary */

.mainTable {
  /* margin: 1%; */
  margin-top: 20px;
  padding: 3%;
  border: 1px solid #e8edf6;
  border-radius: 10px;
  /* margin-top: 500px; */
  text-align: center;
}

/* .mainTable * {
  padding: 2%;
} */
.table {
  border: 1px solid #e8edf6;
  border-radius: 10px;
}

.tableHeadings {
  display: flex;
  /* justify-content: space-between; */
}

.tableValues {
  display: flex;
  /* grid-template-columns: auto; */
  justify-content: space-between;
  align-items: center;
}

/* .values:nth-of-type(odd){
  background: #f5f6fa;
} */
.values:nth-of-type(even) {
  background: #f5f6fa;
}

.tableValues * {
  width: 14%;
  font-size: 90%;
  font-weight: bold;
  padding: 2%;
}

.tableHeadings * {
  width: 14%;
  font-size: 90%;
  font-weight: bold;
  color: #003087;
  padding: 2%;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
}

.sort {
  display: flex;
  width: 20%;
  justify-content: space-between;
}

.sort p {
  border: 1px solid #e5e5e5;
  padding: 5%;
}

.map-title {
  display: none;
}

.indiaMap1 {
  display: flex;
  padding: 1% 2%;
  margin-top: 20px;
  /* margin-left: 10px; */
  /* margin-right: 10px; */
  border: 1px solid #e8edf6;
  /* height: 600px; */
}

.indiaMap {
  width: 100%;
  display: flex;
  margin-top: 100px;
}

.dataMap {
  /* background-color: rgb(232, 237, 246); */
  /* width: 600px; */
  /* height: 400px; */
  position: absolute;
  left: 280px;
  /* margin-top:120px; */
}

.indiaMap svg {
  height: 600px;
  margin-top: -35px;
  /* margin-left: 50px; */

  /* position: fixed;
   */
  /* z-index: -1; */
  /* margin-right: 25%;*/
}

.indiaMap svg path {
  cursor: pointer;
}

.indiaMap path:hover {
  fill-opacity: 0.8;
  cursor: pointer;
}

.HoverInfo {
  position: fixed;
  min-width: 8ch;
  background-color: white;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.3);
  padding: 7px;
  border-radius: 4px;
  margin-left: 100px;
}

.HoverInfo p {
  margin: 0px;
  font-size: 0.9em;
}
.sortStatesHeatMap{
  cursor: pointer;
  border: 1px solid #e5e5e5;
  padding: 20px;
  padding-left: 0px;
}

.sortStateSection{
  max-height: 350px;
    z-index: 1;
    position: absolute;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 30%);
    transition: opacity 0.3s;
    border-radius: 5px;
    text-align: left;
    right: 0px;
    padding: 20px;
    padding-left: 0px;
}
.sortlabel{
  display: flex;
  width: 100%;
  padding: 10px;
  padding-left: 0px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  cursor: pointer;
}
.sortlabel *{
  margin-right: 10px;
}


.dataMap {
  width: 35%;
}

.stateDetails {
  width: 150%;
  margin-left: -20%;
}

.stateHeaders {
  display: flex;
  color: #5b5c60;
  margin: 0 25px;
}

.state {
  width: 18%;
  margin: 5px 15px;
  text-align: center;
}

.stateValues {
  /* width: 60vw; */
  height: 500px;
  overflow-y: scroll;
  /* border-bottom: ; */
  /* margin: 0 15px; */
}

.stateValues::-webkit-scrollbar {
  display: none;
}

.stateName {
  width: 30%;
}

.stateData {
  display: flex;
  border-bottom: 1px solid lightgray;
  padding: 15px;
  margin: 0 25px;
  align-items: center;
  justify-content: center;
}

.stateSummaryHeading {
  display: flex;
  width: 100%;
  height: 10%;
  /* justify-content: space-between; */
  padding: 1% 5% 0;
  /* align-items: center; */
}

.stateSortSection {
  display: flex;
  position: absolute;
  left: 75%;
  /* align-items: center; */
  /* right: ;  */
  /* left : -200px; */
}

.stateSortSection select {
  width: 120px;
  height: 50px;
  border: 1px solid #e5e5e5;
  /* padding: 5%; */
  background-color: white;
  /* margin-top: 5%; */
}

/* .stateSortSection p {
  width: 150px;
  height: 80px;
  /* padding: 10% 1%; */

.stateSortSection * {
  margin-left: 25px;
  /* margin-right: -25px; */
}

.sortProductSection{
  
  max-height: 250px;
  z-index: 1;
  position: absolute;
  /* width: 100%; */
  width: 200px;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 30%);
  transition: opacity 0.3s;
  border-radius: 5px;
  text-align: left;
  right: 250px;
  padding: 20px;
  overflow-y: scroll;
  /* padding-left: 0px;  */
}
.productSortSection{
  display: flex;
  padding:30px;
  width: 100px;
  cursor: pointer;
}

/*
#root-svg-group{
  top: 700px;
}  */

/* .clicks:active{
  background-color: rgb(0, 48, 135);
} */




/* responsive style for the website */